/*
 |------------------------------------------------------------------------------
 | Microsoft Only Styles
 |------------------------------------------------------------------------------
 |
 | All the styles here will be conditionally loaded using a feature media query.
 | For in-line MS conditional loading see @mixin ms-fallback.
 |
 | Note: IE conditional comments support was removed in IE10 and is not usable.
 | @see https://stackoverflow.com/q/19446584
 |
 */

/**
 * Edge specific styles go here
 */
@supports (-ms-ime-align:auto) {
  //
}

/**
 * IE10+ specific styles go here
 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /**
   * @source styles.scss
   *
   * IE11 image down scaling is very poor and requires both width and height set
   * and using the same units, if just on axis is set the image is distorted,
   * best to set no width and height and let auto be inherited for both
   */
  .acc-topbar-brand-link-img {
    height: 28px;
    width: 96.875px;
  }

  /**
   * @source: perspective.scss
   *
   * IE11 needs height to position correctly [#161326725]
   */
  .acc-card-bookmark {
    height: 70px;
  }

  /**
   * @source: dashboard.scss
   */
  .acc-index-card {
    // IE 11 fix
    & > * {
      width: 100%;
    }
  }

  /**
   * @source: ReportHeaderRightActions.tsx
   *
   * Fix IE 11 handling of flex box report header right actions.
   */
  .align-report-content {
    .sub-header {
      .actions {
        width: 19rem;

        .print-button {
          width: 2.5rem;
        }
      }
    }
  }
}
